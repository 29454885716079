import { defineStore } from 'pinia'
import en from '../locales/en';
import hu from '../locales/hu';
import ro from '../locales/ro';
import es from '../locales/es';


export const useWebStore = defineStore('web', {

  state: () => ({
    screenSize: 'mobile',
    navOpen: false,
    gdprVisible:false,
    complaintVisible:true,
    rPetVisible:false,

    //HEAD TITLE TAG
    titleBase: 'Poliol - ',

    sharedStyle: {
      '--titleFont': '60px',
      '--largeTitleFont': '63px',
      '--textFont': '21px',
      '--smallTextFont': '16px',
      '--largeTextFont': '23px',
      '--xLargeTextFont': '28px',
      '--xSmallTitleFont': '45px',
      '--smallTitleFont': '55px'
    },
    sites: [],
    showTheForm: false,
    resetNavbarHeight: false,
    navbarHeight:'4em',
    chosenTab: undefined,
    languages:['hu','ro','es','en'],
    chosenLang: undefined,

    finishedTenders:[
      {id:'GINOP-3.2.2-8-2-4-16-2020-02008', who:'POLIOL PET Packaging Csomagolástechnikai Kft.',title:'Poliol Pet Packaging Kft informatikai fejlesztése'},
      {id:'GINOP-1.2.10-19-2019-00135', who:'POLIOL PET Packaging Csomagolástechnikai Kft.',title:'Robothegesztő cella beszerzése'},
      {id:'GINOP-2.1.8-17-2018-03529', who:'POLIOL PET Packaging Csomagolástechnikai Kft.',title:'Az innovatív PE UNI flakonfúvó gépcsalád versenyképességének fejlesztése'},
      {id:'GINOP-1.3.1-15-2016-00339', who:'POLIOL PET Packaging Csomagolástechnikai Kft.',title:'Piaci megjelenés támogatása a Poliol Pet Packaging Kft.-nél'}
    ],
    
    productCategories:[],
    products: [
      {
        id: 'rezsu-kanal',
        category: 'buckets',
        type: 'foldmunka',
        image: 'fix-rezsu-kanal.webp',
        secondImage: 'rezsu_bontott.webp',
      },
      {
        id: 'hidraulikus-rezsu-kanal',
        category: 'buckets',
        type: 'foldmunka',
        image: 'hidraulikus-rezsu-kanal.webp',
      },
      {
        id: 'melyaso-kanal-korom-nelkul',
        category: 'buckets',
        type: 'foldmunka',
        image: 'melyaso-kanal-korom-nelkul.webp',
      },
      {
        id: 'rezsu-kanal-varos',
        category: 'buckets',
        type: 'varos',
        image: 'fix-rezsu-kanal.webp',
      },
      {
        id: 'melyaso-kanal',
        category: 'buckets',
        type: 'foldmunka',
        image: 'melyaso-kanal-korommel.webp',
      },
      {
        id: 'trapez-kanal-foldmunka',
        category: 'buckets',
        type: 'foldmunka',
        image: 'trapez-kanal.webp',
      },
      {
        id: 'melyaso-kanal-korom-nelkul-kozmu',
        category: 'buckets',
        type: 'kozmu',
        image: 'melyaso-kanal-korom-nelkul.webp',
      },
      {
        id: 'ripper',
        category: 'buckets',
        type: 'foldmunka',
        image: 'ripper.webp',
      },
      {
        id: 'hidraulikus-rezsu-kanal-kozmu',
        category: 'buckets',
        type: 'kozmu',
        image: 'hidraulikus-rezsu-kanal.webp',
      },
      {
        id: 'erositett-melyaso-kanal',
        category: 'buckets',
        type: 'banyaszat',
        image: 'erositett-melyaso-kanal.webp',
      },
      {
        id: 'erositett-rakodo-kanal',
        category: 'buckets',
        type: 'banyaszat',
        image: 'erositett-rakodo-kanal.webp',
      },
      {
        id: 'rakodo-kanal',
        category: 'buckets',
        type: 'varos',
        image: 'rakodo-kanal.webp',
      },
      {
        id: 'ripper-banyaszat',
        category: 'buckets',
        type: 'banyaszat',
        image: 'ripper.webp',
      },
      {
        id: 'hidraulikus-kotro-XCMG-gyorscserelo',
        category: 'buckets',
        type: 'gyorscserelok',
        image: 'hidraulikus-kotro-xcmg-gyorscserelo.webp',
      },
      {
        id: 'hidraulikus-rakodo-VOLVO-gyorscserelo',
        category: 'buckets',
        type: 'gyorscserelok',
        image: 'hidraulikus-rakodo-volvo-gyorscserelo.webp',
      },
      {
        id: 'balavilla',
        category: 'buckets',
        type: 'faipar',
        image: 'balavilla.webp',
      },
      {
        id: 'tragyavilla',
        category: 'buckets',
        type: 'faipar',
        image: 'tragyavilla.webp',
      },
      {
        id: 'konnyuanyag-kanal',
        category: 'buckets',
        type: 'faipar',
        image: 'konnyuanyag-kanal.webp',
      },
      {
        id: 'magasuritesu-kanal',
        category: 'buckets',
        type: 'faipar',
        image: 'magasuritesu-kanal.webp',
      },
      {
        id: 'raklapvilla',
        category: 'buckets',
        type: 'banyaszat',
        image: 'raklapvilla.webp',
      },
      {
        id: 'pap1eco',
        category: 'blowingMachine',
        image: 'PAP-1-ECO.webp',
        values: ['1', '0.75 L', '800']
      },
      {
        id: 'pap1',
        category: 'blowingMachine',
        image: 'PAP-1.webp',
        values: ['1', '0.75 L', '900']
      },
      {
        id: 'pap1hotFill',
        category: 'blowingMachine',
        image: 'PAP-1.webp',
        values: ['1', '0.75 L', '650']
      },
      {
        id: 'pa1eco',
        category: 'blowingMachine',
        image: 'dummy_300x150.webp',
        values: ['1', '2.25 L', '800']
      },
      {
        id: 'pa1hotFill',
        category: 'blowingMachine',
        image: 'dummy_300x150.webp',
        values: ['1', '2.25 L', '650']
      },
      {
        id: 'pe15uni',
        category: 'blowingMachine',
        image: 'PE-15-UNI.webp',
        values: ['1', '6 L', '1300']
      },
      {
        id: 'pe110uni',
        category: 'blowingMachine',
        image: 'PE-110-UNI.webp',
        values: ['1', '12 L', '1200']
      },
      {
        id: 'pap2',
        category: 'blowingMachine',
        image: 'PAP-1.webp',
        values: ['2', '0.3 L', '1800']
      },
      {
        id: 'pa2ecoplus',
        category: 'blowingMachine',
        image: 'PA-2-ECO.webp',
        values: ['2', '2 L', '1400']
      },
      {
        id: 'pe2uni',
        category: 'blowingMachine',
        image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.webp',
        values: ['2', '2.5 L', '3200']
      },
      {
        id: 'pe2uniHotFill',
        category: 'blowingMachine',
        image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.webp',
        values: ['2', '2.5 L', '1800']
      },
      {
        id: 'pe25uni',
        category: 'blowingMachine',
        image: 'PE-25-UNI-és-PA-210.webp',
        values: ['2', '6 L', '2500']
      },
      {
        id: 'pa210uni',
        category: 'blowingMachine',
        image: 'PE-25-UNI-és-PA-210.webp',
        values: ['2', '12 L', '2000 bph']
      },
      {
        id: 'pe4uni',
        category: 'blowingMachine',
        image: 'PE-4-UNI.webp',
        values: ['4', '2.5 L', '6400']
      },
      {
        id: 'pe6uni',
        category: 'blowingMachine',
        image: 'dummy_300x150.webp',
        values: ['6', '2.5 L', '9200 bph']
      },
      {
        id: 'pe12uni',
        category: 'blowingMachine',
        image: 'PE-12-UNI.webp',
        values: ['12', '0.06 L', '12000']
      }
    ],    
    locale: {
      current: {},
      hu,
      en,
      ro,
      es
    },
  }),
  getters: {
    getProductTypes: (state) => (category) => {
      if (!state.locale.current || !state.locale.current.prodGroupLocale) {
        console.error('Locale or prodGroupLocale is not properly initialized');
        return [];
      }
      
      const group = state.locale.current.prodGroupLocale.find(a => a.id === category);
      if (!group) {
        console.warn(`No product group found for category: ${category}`);
        return [];
      }
      
      return group.types || [];
    },
    getProducts:(state) => (params)=>{
      const { category, type } = params;
      if(type === undefined)
        return state.products.filter(a => a.category === category)
      else
        return state.products.filter(a => a.category === category && a.type === type)
    },
    getProductPropertyCategories:(state) => (params) =>{
      const { type } = params;
      if(type === 'blowingMachine'){
        return state.locale.current.bbmProdProperties;
      }
    },
    getProductTitle:(state) => (id) =>{
      return state.products.find(a => a.id === id).name
    },
    getProductType:(state) => (id) =>{
      return state.products.find(a => a.id === id).type
    },
    getProductMainImage:(state) => (id) =>{
      return state.products.find(a => a.id === id).image
    },
    getProductSideImage:(state) => (id) =>{
      return state.products.find(a => a.id === id).secondImage
    },
    getProductProperties:(state) => (id) =>{
      return state.products.find(a => a.id === id).properties
    },
    getProductUsage:(state) => (id) =>{
      return state.products.find(a => a.id === id).usage
    },
    getProductText:(state) => (id) =>{
      return state.products.find(a => a.id === id).text
    },
    getPruductReferencePics:(state) => (id) =>{
      return state.products.find(a => a.id === id).noOfRefPics
    },
    getAreaBannerSlidesToShow :(state) =>()=>{
      switch(state.screenSize){
          case 'mobile':
              return 1;
          case 'mobileL':
              return 2;
          case 'tabletP':
              return 3;
          case 'laptop':
            return 3;
            case 'desktop':
              return 4;
          default:
              return 5;
      }
    },
  },

  actions: {
    overallSetting( payload){
      this.setScreenSize(payload);
      this.setSharedStyle(payload);
    },

    navigateToPage(route, replace = true) {
      if (route === 'webshop') {
        window.open('https://flakon.hu', '_blank');
      } else {
        // Construct the full target route
        const targetRoute = `/${this.chosenLang}/${route}`;
    
        // Perform route navigation
        this.chosenTab = route;
    
        // Replace the full route
        navigateTo(targetRoute, { replace: replace });
      }
    },
    setLangThenNavigate(payload){
      //console.log('Chosen Tab: ' + this.chosenTab)
      let currentPageIndex= this.sites.findIndex((site)=>site.url===this.chosenTab)
      //this.language = payload
      this.setLanguage(payload)
      //console.log('current Page Index: ' + currentPageIndex)
      this.chosenTab = this.sites[currentPageIndex].url
      this.navigateToPage(this.sites[currentPageIndex].url, true)
    },

    setScreenSize (n){
      this.screenSize = n
    },

    setSharedStyle(n){
      switch (n){
        case 'mobile':{
          this.sharedStyle["--titleFont"] = '40px';
          this.sharedStyle["--largeTitleFont"] = '43px';
          this.sharedStyle["--smallTitleFont"] = '37px';
          this.sharedStyle["--textFont"] = '19px';
          this.sharedStyle["--largeTextFont"] = '20px';
          this.sharedStyle["--smallTextFont"] = '16px';
          break;
        }
        case 'mobileL':{
          this.sharedStyle["--titleFont"] = '43px';
          this.sharedStyle["--largeTitleFont"] = '45px';
          this.sharedStyle["--smallTitleFont"] = '40px';
          this.sharedStyle["--textFont"] = '19px';
          this.sharedStyle["--largeTextFont"] = '20px';
          this.sharedStyle["--smallTextFont"] = '18px';
          break;
        }
        case 'tabletP':{
          this.sharedStyle["--titleFont"] = '47px';
          this.sharedStyle["--largeTitleFont"] = '48px';
          this.sharedStyle["--smallTitleFont"] = '43px';
          this.sharedStyle["--textFont"] = '20px';
          this.sharedStyle["--largeTextFont"] = '21px';
          this.sharedStyle["--smallTextFont"] = '19px';
          break;
        }
        case 'tabletL':{
          this.sharedStyle["--titleFont"] = '50px';
          this.sharedStyle["--largeTitleFont"] = '51px';
          this.sharedStyle["--smallTitleFont"] = '47px';
          this.sharedStyle["--textFont"] = '20px';
          this.sharedStyle["--largeTextFont"] = '21px';
          this.sharedStyle["--smallTextFont"] = '19px';
          break;
        }
        case 'laptop':{
          this.sharedStyle["--titleFont"] = '53px';
          this.sharedStyle["--largeTitleFont"] = '54px';
          this.sharedStyle["--smallTitleFont"] = '48px';
          this.sharedStyle["--textFont"] = '21px';
          this.sharedStyle["--largeTextFont"] = '22px';
          this.sharedStyle["--smallTextFont"] = '20px';
          break;
        }
        case 'desktop':{
          this.sharedStyle["--titleFont"] = '60px';
          this.sharedStyle["--largeTitleFont"] = '63px';
          this.sharedStyle["--smallTitleFont"] = '55px';
          this.sharedStyle["--textFont"] = '21px';
          this.sharedStyle["--largeTextFont"] = '23px';
          this.sharedStyle["--smallTextFont"] = '20px';
          break;
        }
        default:{
          break;
        }
      }
    },
    setFormVisibility(state,){
      state.showTheForm = !state.showTheForm;
    },
    setNavbarHeight(state){
      state.resetNavbarHeight = !state.resetNavbarHeight
    },
    setLanguage(n){
      //SET THE LANGUAGE VARIABLES
      this.locale.current = this.locale[n]
      
      //SET THE LANGUAGE MARKER
      this.chosenLang = n;
      //SET NAVBAR EXPRESSIONS
      this.sites = this.locale.current.sites


      //SET PRODUCT LOCALES
      //go through the products, look for the ID in the new locales, and set the name, properties, usage, text
      this.products.forEach((product)=>{
        //console.log(product.id)
        let newValues = this.locale.current.prodLocale.find((a)=>a.id===product.id)
        //console.log(newValues)
        if(newValues !== undefined){
          product.name=newValues.name
          product.properties=newValues.properties
          product.usage=newValues.usage
          product.text=newValues.text
          product.overlayText=newValues.overlayText
        }
      })
      //SET PRODUCT GROUP LOCALES
      this.productCategories = this.locale.current.prodGroupLocale

    },
    getLocaleString(what){
      return this.locale.current[what]
    },
    showGdpr(){
      this.gdprVisible=true
    },
    
  },
  modules: {},
})
