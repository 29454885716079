export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.provide('emailClient', (url: string, options: any = {}, params: Record<string, string> = {}) => {
      // Construct query string from params
      const queryString = new URLSearchParams(params).toString();
      
      // Append query string to the URL if params are provided
      const fullUrl = queryString ? `${url}?${queryString}` : url;
      
      options.headers = options.headers || {};
      options.headers.Authorization =  nuxtApp.$config.public.apiKey;
      options.method = 'POST';
      return $fetch(fullUrl,options);
    });
});
  