<script setup>
  import { useWebStore } from '@/store/web.js'



  const store = useWebStore();
  const { $viewport } = useNuxtApp()
  const isMounted = ref(false) 
  const colorMode = useColorMode()
  console.log(colorMode.preference)
  const logoSrc = computed(() =>  colorMode.value === 'dark' ? '/poliol_logo_feher.webp' : '/poliol_logo.webp')
  

  const AsyncPdf = defineAsyncComponent(() => import('../components/pdf.vue'));


  setTimeout(()=>{
    store.rPetVisible = true;
  },3000)
  

  watch($viewport.breakpoint, (newBreakpoint, oldBreakpoint) => {
    console.log('Breakpoint updated:', oldBreakpoint, '->', newBreakpoint)
    updateScreenSize(newBreakpoint);
  })


  updateScreenSize($viewport.breakpoint.value)
  

function updateScreenSize(n){
  if(['mobile','mobileMedium'].includes(n)){
      store.overallSetting('mobile')
    }
    else if(n === 'mobileWide'){
      store.overallSetting('mobileL')
    }
    else if(n === 'tablet'){
      store.overallSetting('tabletP')
    }
    else if(n === 'desktop'){
      store.overallSetting('laptop')
    }
    else if(['desktopMedium','desktopWide'].includes(n)){
      store.overallSetting('desktop')
    }
}


//META PIXEL
useHead({
  script: [
    {
      children: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NV7RKW4');
      `,
      type: 'text/javascript'
    }
    ],
})
</script>
<template>
  <div>
    <NuxtLayout>
      <NuxtPage
      id="app"
          @click="store.navOpen? store.navOpen = false:''"
          :class="store.screenSize"
          />
    </NuxtLayout>
    <!-- GDPR Modal -->
    <ClientOnly>
      <UModal v-model="store.gdprVisible"
              :ui="{width:'w-full sm:max-w-3xl',margin: 'sm:my-24', padding: 'p-4 sm:p-2'}"
              >
          <div class="p-4 w-max mx-auto h-2/6 mt-2 flex flex-col gap-5">
              <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" size="xl" class="ml-auto mr-1 p-0" @click="store.gdprVisible = false" />
              <AsyncPdf class="" url="POLIOL_Általános_adatkezelési_szabályzat_2024.pdf"/>
          </div>
      </UModal>
      </ClientOnly>

      <!-- rPet Modal -->
      <ClientOnly>
      <UModal v-model="store.rPetVisible"
              
              :ui="{width:'w-full sm:max-w-3xl',margin: 'sm:my-24', padding: 'p-4 sm:p-2'}"
              >
          <div class="p-5 w-full mx-auto h-2/6 mt-2 flex flex-col gap-5 text-lg dark:bg-slate-800 dark:text-white">
            <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" size="xl" class="ml-auto mr-1 p-0" @click="store.rPetVisible = false" />
            <p class="InterMedium">{{ store.getLocaleString('rpet1') }}</p>
            <p class="mt-4">{{ store.getLocaleString('rpet2') }} <a class="underline text-orange-500" href="https://net.jogtar.hu/jogszabaly?docid=a2100349.kor&timeshift=20250101" target="_blank" rel="noopener noreferrer">{{ store.getLocaleString('rpet6') }}</a> {{ store.getLocaleString('rpet8') }}.</p>
            <p class="mt-4">{{ store.getLocaleString('rpet3') }} <a class="underline text-orange-500" href="https://eur-lex.europa.eu/legal-content/HU/TXT/?uri=CELEX%3A32019L0904" target="_blank" rel="noopener noreferrer">{{ store.getLocaleString('rpet7') }}</a> {{ store.getLocaleString('rpet9') }}.</p>
            <p class="mt-4">{{ store.getLocaleString('rpet4') }}</p>
            <p class="mt-4">{{ store.getLocaleString('rpet5') }}</p>
            <img class="w-48" :src="logoSrc" :alt="'poliol kft. logo'">
          </div>
      </UModal>
      </ClientOnly>
      <!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV7RKW4"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->
  </div>
</template>
<style lang="scss">
  body{
    margin: unset !important;
  }
  #app{
    --dark-font-color:#2c3e50;
    --orange-color: #F67013;
    --dark-background-color: rgb(21,20,28);


    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color:var(--dark-font-color);
    background-color: white;
    margin: 0 auto;
    overflow-x: hidden;


        //FONTOK
    @font-face {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("/font/Inter-Regular.woff2") format("woff2");
    }
    @font-face {
      font-family: "Space-Grotesk";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("/font/SpaceGrotesk-VariableFont_wght.ttf") format("woff2");
    }
    @font-face { font-family: "Inter-ExtraBold";
      font-style: normal;
      font-weight: 4800;
      font-display: swap;
      src: url("/font/Inter-ExtraBold.woff2") format("woff2");
    }
    @font-face { font-family: "Inter-Medium";
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url("/font/Inter-Medium.woff2") format("woff2");
    }

    :root { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      :root { font-family: 'Inter var', sans-serif; }
    }
  }

</style>